exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-fun-extras-index-tsx": () => import("./../../../src/pages/fun-extras/index.tsx" /* webpackChunkName: "component---src-pages-fun-extras-index-tsx" */),
  "component---src-pages-fun-extras-supercats-index-tsx": () => import("./../../../src/pages/fun-extras/supercats/index.tsx" /* webpackChunkName: "component---src-pages-fun-extras-supercats-index-tsx" */),
  "component---src-pages-games-index-tsx": () => import("./../../../src/pages/games/index.tsx" /* webpackChunkName: "component---src-pages-games-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-music-index-tsx": () => import("./../../../src/pages/music/index.tsx" /* webpackChunkName: "component---src-pages-music-index-tsx" */),
  "component---src-pages-template-tsx": () => import("./../../../src/pages/template.tsx" /* webpackChunkName: "component---src-pages-template-tsx" */)
}

